import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useEffect } from 'react';
import { Seo } from '../../components/Seo';
import { getLocalizedUrl } from '../../shared/utils';

const PlatformPage = props => {
    const location = props.location.pathname;
    const url = getLocalizedUrl('/platform/aida', location);
    useEffect(() => {
        // noinspection JSIgnoredPromiseFromCall
        navigate(url);
    }, [ url ]);
    return null;
};

PlatformPage.propTypes = {
    location: PropTypes.shape({ pathname: PropTypes.string }).isRequired
};

export default PlatformPage;

export const Head = () => (
    <Seo />
);
